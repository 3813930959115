<template>
  <v-container>
    <HeaderTix />
    <v-main class="bg-color w-100 pr-0">
      <router-view :key="$route.fullPath" />
    </v-main>
    <FooterTix class="bg-color" />
  </v-container>
</template>

<script>
import HeaderTix from "@/components/Header.vue";
import FooterTix from "@/components/Footer.vue";

export default {
  name: "MainView",

  components: {
    HeaderTix,
    FooterTix,
  },

  mounted() {
    setTimeout(() => {
      this.scrollTop();
    }, 500);
  },

  methods: {
    scrollTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
};
</script>

<style>
a {
  text-decoration: none;
  color: black;
}

.bg-color {
  background-color: #f8f8f8;
}
</style>