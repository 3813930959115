<template>
  <v-container>
    <v-row no-gutters align="center" justify="center">
      <v-col cols="12" class="d-flex justify-center">
        <v-sheet width="300">
          <v-img
            @contextmenu.prevent
            clas
            src="@/assets/ilustration_waiting.gif"
            width="300"
          />
          <div v-if="this.resultCode == '00'">
            <p class="text-h5 font-weight-bold text-center">
              Pembayaran Berhasil
            </p>
            <p class="text-body-2 font-weight-medium text-center">
              Halaman ini akan tertutup dalam 5 detik dan kamu akan kami alihkan
              ke halaman e-tix.
            </p>
            <p class="text-h6 font-weight-black text-center mt-2">
              {{ time_output }}
            </p>
          </div>

          <div v-else>
            <p class="text-h5 font-weight-bold text-center">Pembayaran Gagal</p>
            <p class="text-body-2 font-weight-medium text-center">
              Halaman ini akan tertutup dalam 5 detik dan kamu akan kami alihkan
              ke halaman e-tix.
            </p>
            <p class="text-h6 font-weight-black text-center mt-2">
              {{ time_output }}
            </p>
          </div>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      merchantOrderId: this.$route.query.merchantOrderId,
      resultCode: this.$route.query.resultCode,
      reference: this.$route.query.reference,
      time: null,
      time_output: null,
    };
  },

  created() {
    this.time = 5000;
  },

  methods: {
    countdown() {
      if (this.time > 0) {
        setTimeout(() => {
          let time_final = this.time / 1000;
          this.time_output = `${time_final} Detik`;
          this.time -= 1000;
        }, 1000);
      } else {
        let new_window = open(location, "_self");
        new_window.close();
      }
    },
  },

  mounted() {
    setInterval(() => {
      this.countdown();
    }, 1000);
  },
};
</script>