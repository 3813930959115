<template>
  <v-container class="pa-0">
    <v-row :style="{ height: '100vh' }" class="justify-center align-center ma-auto">
      <v-col cols="12">
        <v-sheet max-width="1000" class="mx-auto">
          <v-card elevation="6">
            <v-row no-gutters align="center" justify="center">
              <v-col cols="12" xl="6" lg="6" md="6" sm="6">
                <router-link :to="{ name: 'home' }">
                  <v-img @contextmenu.prevent class="mx-auto" src="@/assets/logo.svg" width="180" height="100"></v-img>
                </router-link>
                <v-card-text class="text-white text-center">
                  <p class="text-h6 text-center text-primary">
                    Beli beragam jenis tiket cepat dan mudah
                    <strong>hanya di e-tix</strong>
                  </p>
                </v-card-text>

                <v-img @contextmenu.prevent class="mx-auto" src="@/assets/01_ilustration.svg" width="350"
                  height="350"></v-img>
              </v-col>

              <v-col xl="4" lg="4" md="4" sm="4">
                <v-card-text>
                  <p class="text-h5 mb-2 text-primary font-weight-bold">Login</p>

                  <v-card>
                    <v-alert v-if="error.service" type="error" v-model="error.service" closable>
                      Service sedang bermasalah
                    </v-alert>

                    <v-alert v-else-if="error.user" type="error" v-model="error.user" closable>
                      {{ error.user }}
                    </v-alert>

                    <v-card-text>
                      <v-form ref="login" v-model="valid" lazy-validation>
                        <v-text-field @keyup.enter="handleSubmit()" @keydown="error.user = ''" v-model="input.email"
                          :rules="rules.email" variant="outlined" :error-messages="error.email"
                          placeholder="Email/Nomor Whatsapp" type="email" persistent-hint
                          hint="Masukkan email/nomor whatsapp yang kamu gunakan saat mendaftar" outlined dense
                          color="primary" required class="mb-4" />
                        <v-text-field @keyup.enter="handleSubmit()" @keydown="
                          error.password = false;
                        error.user = '';
                        " :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
                          @click:append-inner="visible = !visible" :type="visible ? 'text' : 'password'"
                          v-model="input.password" :rules="rules.password" variant="outlined"
                          :error-messages="error.password" placeholder="Password" outlined dense color="primary"
                          required />

                        <div class="text-right mb-4">
                          <v-btn to="/reset-password" size="x-small" variant="text"
                            class="text-capitalize text-primary text-caption">Lupa Password?</v-btn>
                        </div>

                        <v-btn :loading="isProcessing" :disabled="isProcessing || !valid" @click.prevent="handleSubmit"
                          class="text-capitalize mt-10" :color="isProcessing || !valid ? 'grey' : 'primary'" block>
                          Masuk
                        </v-btn>
                      </v-form>

                      <v-card-text>
                        <h6 class="text-center text-grey text-caption">
                          Belum punya akun?
                          <v-btn class="font-weight-bold text-capitalize pa-0 text-caption" size="x-small"
                            color="primary" variant="text" to="/register">Daftar</v-btn>
                        </h6>
                      </v-card-text>
                    </v-card-text>
                  </v-card>
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
          <footer-copyright />
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>

  <v-snackbar snackbar color="white" v-model="snackbar.status" rounded="pill">
    <div class="text-black">
      {{ snackbar.text }}
    </div>

    <template v-slot:actions>
      <v-btn color="pink" rounded="pill" variant="text" @click="snackbar.status = false">
        Tutup
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import isEmail from "validator/lib/isEmail";
import isMobilePhone from "validator/lib/isMobilePhone";
import FooterCopyright from "@/components/FooterCopyright.vue";

export default {
  name: "LoginView",

  components: {
    FooterCopyright,
  },

  data() {
    return {
      valid: false,
      visible: false,
      isProcessing: false,
      input: {
        email: "",
        password: "",
      },
      rules: {
        email: [
          (v) => !!v || "Kamu perlu mengisi bagian ini",
          (v) => {
            var regExp = /[a-zA-Z]/g;
            if (regExp.test(v)) {
              return isEmail(v) || "Tolong masukkan alamat email yang benar";
            } else {
              return (
                isMobilePhone(v) || "Tolong masukkan nomor whatsapp yang benar"
              );
            }
          },
        ],
        password: [(v) => !!v || "Password perlu diisi"],
      },
      error: {
        email: false,
        password: false,
        service: false,
        user: "",
      },
      snackbar: {
        status: false,
        text: "",
      },
    };
  },

  methods: {
    async login() {
      await this.axios
        .post(
          "auth/login",
          {
            email: this.input.email,
            password: this.input.password,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          this.$store.dispatch("login", response.data.response);
          if (localStorage.getItem("LAST_ROUTE")) {
            this.$router.push(this.$router.options.history.state.back);
            localStorage.removeItem("LAST_ROUTE");
          } else {
            this.$router.go(-1);
          }
        })
        .catch((error) => {
          switch (error?.response?.status) {
            case 420: {
              // password salah
              this.error.password =
                "Tuliskan password yang kamu gunakan saat mendaftar";
              break;
            }
            case 404: {
              this.error.user = "User tidak ditemukan";
              break;
            }
            case 401: {
              // email belum terdaftar
              this.error.email = "Email tidak ditemukan";
              break;
            }
            case 402: {
              // no hp belum terdaftar
              this.error.email = "Nomor whatsapp tidak ditemukan";
              break;
            }
            case 422: {
              this.$store.state.credential = this.input.email;
              this.$router.push({ name: "email-verification" });
              break;
            }
            default: {
              this.error.service = true;
            }
          }
        });
    },

    async handleSubmit() {
      const { valid } = await this.$refs.login.validate();
      if (valid) {
        this.isProcessing = true;
        this.login();
        setTimeout(() => (this.isProcessing = false), 2000);
      }
    },

    showSnackbar(text) {
      this.snackbar.status = true;
      this.snackbar.text = text;
    },
  },
  watch: {
    "input.password"() {
      this.error.password = false;
    },
    "input.email"() {
      this.error.email = false;
    },
  },
};
</script>

<style scoped>
.blue {
  background-color: rgb(var(--v-theme-primary));
}

.rounded-br-xl {
  border-bottom-right-radius: 300px !important;
}

.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
</style>
