<template>
  <ResetRequest v-if="getStateReset.email" />
  <InputOTP v-else-if="getStateReset.otp" />
  <InputNewPassword v-else-if="getStateReset.password" />
</template>

<script>
import { mapGetters } from "vuex";
import ResetRequest from "@/components/ResetPassword/ResetRequest.vue";
import InputOTP from "@/components/ResetPassword/InputOTP.vue";
import InputNewPassword from "@/components/ResetPassword/InputNewPassword.vue";

export default {
  components: {
    ResetRequest,
    InputOTP,
    InputNewPassword,
  },

  computed: {
    ...mapGetters(["getStateReset"]),
  },

  data() {
    return {};
  },

  methods: {
    back() {
      this.$router.push("/login");
    },
  },
};
</script>

<style scoped></style>
